


















































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
@Component({
    components: {

    }
})
export default class personStatistics extends Vue {
    [x: string]: any;
    public form: any = {
        size: 10, current: 1, result: ''
    };
    public datacreateTime: any[] = [];
    private tableData: any[] = [
    ];
    public doStatus: any[] = [
        {
            name: '全部',
            id: '',
        },
        {
            name: '已同步',
            id: '1',
        },
        {
            name: '未同步',
            id: '0',
        }
    ];
    public loading: Boolean = false;
    @Inject(statisticsApi) private statisticsApi!: statisticsApi;
    public async created(): Promise<void> {
        await this.getList();
    }
    public search() {
        this.getList()
    }
    // 重置
    public reset() {
        this.form = {
            size: this.form.size,
            current: 1,
            result: ''
        };
        this.datacreateTime = [];
        this.getList();
    }
    //分页相关
    public handleSizeChange(size: number) {
        this.form.size = size;
        this.getList();
    }
    public handleCurrentChange(current: number) {
        this.form.current = current;
        this.getList();
    }
    //获取数据源
    public async getList() {
        var that = this;
        that.loading = true;
        if (that.datacreateTime && that.datacreateTime.length > 0) {
            that.form.endTime = that.datacreateTime[1]
            that.form.startTime = that.datacreateTime[0]
        } else {
            delete that.form.startTime
            delete that.form.endTime
        }
        const res = await that.statisticsApi.getswiftlogListG(that.form);
        that.tableData = res.records;
        that.form.total = res.total;
        that.form.pages = res.pages;
        that.form.size = res.size;
        that.form.current = res.current;
        that.loading = false;
        if (res.records.length <= 0) {
            that.form.pages = 1;
            that.form.size = 10;
            that.form.current = 1;
            that.form.total = 0;
        }
    }
    //完成处理
    private async doAnything(row: any): Promise<void> {
        let param = {
            id: row.id
        }
        try {
            await this.$confirm('确认处理完成?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'info'
            });
            await this.statisticsApi.editSure(param);
            this.getList()
            this.$message({
                message: '处理完成',
                type: 'success'
            });
        } catch (e) {
            this.$message({
                type: 'info',
                message: '已取消处理'
            });
        }
    }
    private mounted() {
        document.onkeydown = (e) => {
            let key = (window.event as any).keyCode;
            if (key == 13) {
                this.search()
            }
        }
    }
}
