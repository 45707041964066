


















import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
import Person from '@/components/swiftlog/person.vue';
import Framework from '@/components/swiftlog/framework.vue';
@Component({
    components: {
        Person, Framework
    }
})
export default class swiftlog extends Vue {
    [x: string]: any;
    public activeName = "first";
    @Inject(statisticsApi) private statisticsApi!: statisticsApi;
    public syncShow: Boolean = true;
    public syncText: String = '同步';
    public async syncClick() {
        var that = this;
        this.$confirm('是否确认全量同步最新的人员和架构信息？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(async () => {
            const res = await that.statisticsApi.getswiftlogSync()
            if (res == 2) {
                // 最新版本不用同步
                that.syncShow = false;
                that.syncText = "同步";
                this.$message({
                    message: '已同步，请勿重复同步！',
                    type: 'error'
                });
            } else {
                that.syncShow = true;
                that.syncText = "同步中";
                setTimeout(async function () { await that.syncStatus(); }, 60000);
            }
        }).catch(() => {

        });
    }
    public async syncStatus() {
        var that = this;
        const res = await that.statisticsApi.getswiftlogSyncstatus();
        console.log('syncStatus', res)
        if (res == 0) {
            that.syncShow = false;
            that.syncText = "同步";
            clearTimeout;
        } else {
            that.syncShow = true;
            that.syncText = "同步中";
            clearTimeout;
            setTimeout(async function () { await that.syncStatus(); }, 60000);
        }
    }
    public async created(): Promise<void> {
        var that = this;
        await that.syncStatus();
    }
}
